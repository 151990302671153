import React from "react"

import Layout from '../components/layout';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import FreeTrial from "../components/free-trial";
import SEO from "../components/seo";

import LeftFeature from '../components/left-feature';
import RightFeature from '../components/right-feature';
import FeaturePararaph from '../components/feature-paragraph'
import {AbaxElectricalTestimonial} from '../components/testimonial';
import AppStoreIcons from '../components/app-store-icons';

import timesheetsWithLeaveSrc from '../images/timesheets-with-leave.png'
import requestLeaveSrc from '../images/leave-request.png';
import leaveCalendarSrc from '../images/leave-calendar.svg';
import kioskKeypadSrc from '../images/kiosk-keypad.png';
import setupWizardSrc from '../images/setup-wizard.svg';
import calendarSrc from '../images/calendar.svg';

import FreeTrialButton from '../components/free-trial-button';

const EmployeeLeaveHolidayTracker = () => (
  <Layout>
    <SEO
    	title="Employee Leave Tracker for Small Businesses"
    	description="Still using manual employee leave requests? Then switch to our digital leave management system today!"
    	 />
    <Navbar />
    <div className="bg-gray-50">
		  <div className="relative bg-white overflow-hidden">
			  <div className="relative pt-6 pb-16 md:pb-20 lg:pb-24 xl:pb-32">
			    <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
			      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
			        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
			          <h2 className="mt-1 text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-5xl">
			            Online <span className="text-highlight-600">leave management</span> for modern companies
			          </h2>
			          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
			            Are you looking to modernise your leave management system in your business? Go paperless with TimeKeeper's built-in employee leave
			            management system.
			          </p>

			          <FreeTrialButton />
			        </div>
			        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
			          <div className="relative mx-auto w-full rounded-lg lg:max-w-md">
			            <button className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline">
			              <img className="w-full" src={calendarSrc} alt="Request Leave" />
			            </button>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
			<div className="py-8 bg-gray-50 overflow-hidden lg:py-24">
			  	<div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
					<LeftFeature
				    	sectionTitle="Digital Employee Leave Requests"
				    	title="Request leave on the go"
				    	body={
				    		<div>
					    		<FeaturePararaph>
						        	Employees can request their holidays and leave from the same app they use to clock in - any time, anywhere. We’ll let them know if anyone else in their team is booked off too, to avoid any conflicts and ensure you’re not left short staffed.
						        </FeaturePararaph>
						        <FeaturePararaph>
						        	Managers can approve and deny requests straight from their email or web portal, and employees can see their booked leave and remaining entitlement any time.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={requestLeaveSrc}
				    	imgAlt="Mobile leave request"
				    />
				    <RightFeature
				    	sectionTitle="Keep on top of your team leave"
				    	title="Interactive wall calendar"
				    	includeShadow={true}
				    	body={
				    		<div>
					    		<FeaturePararaph>
						        	See all of your employees’ leave in one place, so you know who’s off, when. Configure custom leave types to track holidays, training days, sick leave and more, and flag important dates like birthdays and work anniversaries.
						        </FeaturePararaph>
						        <FeaturePararaph>
						        	 Our automatic Public Holidays feature can add bank holiday dates for all of your teams in a few clicks too.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={leaveCalendarSrc}
				    	imgAlt="Leave Calendar "
				     />
				     <LeftFeature
				    	sectionTitle="Set your business leave rules"
				    	title="Custom rules for your business"
				    	body={
				    		<div>
					    		<FeaturePararaph>
						          Want to set policies on how many team members can be off at once, or how much notice employees need to give before taking off on holiday?
						          Set custom rules to fit your company, and we’ll automatically reject requests when your teams’ limits have been reached.
						        </FeaturePararaph>
						        <FeaturePararaph>
						        	Once you’ve set your employee’s annual leave entitlement for the year, we’ll keep track and let them know how much leave they still have left to take.
						        </FeaturePararaph>
						        <AppStoreIcons />
						    </div>
				    	}
				    	imgSrc={setupWizardSrc}
				    	imgAlt="Woman setting up custom rules"
				    />
				    <RightFeature
				    	sectionTitle="Integrated timesheets with leave"
				    	title="Timesheets + Leave = ♡"
				    	body={
				    		<div>
					    		<FeaturePararaph>
						        	Integrated leave tracking means paid leave hours are added to your timesheets automatically; TimeKeeper already knows your employees schedules, so we’ll work out their leave hours and save you the manual entry.
						        </FeaturePararaph>
						        <FeaturePararaph>
						        	Our leave tracking features are available as standard for all employees, so you can keep track of holidays for hourly and salaried staff all in one place, giving you full visibility over your staff’s availability.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={kioskKeypadSrc}
				    	imgAlt="Manage timesheets and leave together "
				     />
				</div>
				<AbaxElectricalTestimonial />
				<FreeTrial
					part1={
						<span>Modernise <span className='text-highlight-600'>employee leave</span> management <br /> in your business today!</span>
					}
				/>
			</div>
		</div>
		<Footer/>
  </Layout>
)

export default EmployeeLeaveHolidayTracker;
